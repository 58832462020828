import React from "react";

const Info = () => {
  return (
  	<div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-6">
    <div className="p-6">
	    <div className="flex items-center gap-2 mb-4">
	      <span className="bg-yellow-300 text-black text-xs px-2 py-1 rounded-full">
	        ★ 5
	      </span>
	      <span className="bg-purple-100 text-purple-600 text-xs px-2 py-1 rounded-full">
	        Искусство
	      </span>
	      <span className="bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full">
	        Погулять
	      </span>
	      <span className="text-xs text-gray-500">11 часов</span>
	    </div>
	    <h1 className="text-2xl font-bold mb-2">
	      Архитектурный гид по европейским столицам. От Рима до Москвы
	    </h1>
	    <p className="text-gray-600 mb-4">
	      Главное об архитектуре 6 столиц, чтобы составить список мест для
	      посещения. Вместо книги Дэвида Уткина и архитектурных путеводителей.
	    </p>
	    <button className="flex items-center text-gray-600 hover:text-gray-800">
	      ♡ В избранное
	    </button>
	</div>
	</div>
  );
};

export default Info;