import React from "react";



const UserLeaderboard = ({ user }) => {
  return (
    <div className="bg-white p-2 rounded-2xl shadow-md w-full mt-4">  
          <div key={user.place} className="flex items-center justify-between p-4 border-b last:border-none">
            {/* Левая часть */}
            <div className="flex items-center space-x-4">
              <span className="text-gray-600 font-semibold w-6">{user.place}</span>
              <div className="w-10 h-10">
                <img src={user.avatar} alt={user.name} className="w-full h-full rounded-full border border-gray-300" />
              </div>
              <span className="text-lg font-medium">({user.name}) Бавархан Есбол</span>
            </div>

            {/* Правая часть */}
            <span className="text-lg font-semibold text-gray-700">{user.score}</span>
          </div>
    </div>
  );
};

export default UserLeaderboard;
