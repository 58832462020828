import React from "react";

const leaders = [
  {
    name: "Иван",
    score: 979,
    place: 1,
    avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149636.png",
    bgColor: "bg-yellow-100",
    crown: "👑",
    badgeColor: "bg-yellow-400",
    textColor: "text-yellow-800",
  },
  {
    name: "Мария Ульянова",
    score: 924,
    place: 2,
    avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149639.png",
    bgColor: "bg-gray-100",
    crown: "🥈",
    badgeColor: "bg-gray-400",
    textColor: "text-gray-800",
  },
  {
    name: "Настя",
    score: 884,
    place: 3,
    avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149640.png",
    bgColor: "bg-orange-100",
    crown: "🥉",
    badgeColor: "bg-orange-400",
    textColor: "text-orange-800",
  },
];

const Leaderboard = () => {
  return (
    <div className="bg-white p-6 rounded-2xl shadow-md w-full">
      <h2 className="text-xl font-bold mb-4">Лидеры</h2>
      <div className="space-y-2">
        {leaders.map((leader, index) => (
          <div
            key={index}
            className={`flex items-center justify-between p-4 rounded-lg ${leader.bgColor}`}
          >
            {/* Левая часть: Аватар + Имя */}
            <div className="flex items-center space-x-4">
              <div className="relative w-12 h-12">
                <img
                  src={leader.avatar}
                  alt={leader.name}
                  className="w-full h-full rounded-full border border-gray-300"
                />
                <span className="absolute -top-1 -right-1 text-lg">{leader.crown}</span>
              </div>
              <span className="text-lg font-semibold">{leader.name}</span>
            </div>

            {/* Правая часть: Место и баллы */}
            <div className="flex items-center space-x-4">
              <span
                className={`px-3 py-1 text-sm font-semibold rounded-full text-white ${leader.badgeColor}`}
              >
                🏆 {leader.place}
              </span>
              <span className="text-lg font-semibold text-gray-700">{leader.score}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Leaderboard;
