import React, { useState } from "react";
import { MagnifyingGlassIcon, FunnelIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const categories = [
  "Все",
  "Аниме",
  "Здоровье и красота",
  "Искусство",
  "История и политика",
  "Карьера и бизнес",
];

const SearchBar = () => {
  const [activeCategory, setActiveCategory] = useState("Все");

  return (
    <div className="bg-white p-4 rounded-2xl shadow-md flex flex-col space-y-3 w-full">
      {/* Поле поиска */}
      <div className="flex items-center bg-gray-100 px-4 py-2 rounded-lg">
        <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" />
        <input
          type="text"
          placeholder="Поиск"
          className="flex-1 bg-transparent outline-none px-3 text-gray-700"
        />
        <button className="p-2 text-gray-500">
          <FunnelIcon className="w-5 h-5" />
        </button>
      </div>

      {/* Фильтры */}
      <div className="flex items-center space-x-2 overflow-x-auto scrollbar-hide">
        {categories.map((category, index) => (
          <button
            key={index}
            onClick={() => setActiveCategory(category)}
            className={`px-4 py-2 rounded-full font-medium text-sm transition ${
              activeCategory === category
                ? "bg-yellow-300 text-black"
                : "bg-gray-100 text-gray-700"
            }`}
          >
            {category}
          </button>
        ))}
        <button className="p-2">
          <ChevronRightIcon className="w-5 h-5 text-gray-400" />
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
