import React from "react";
import { FireIcon, ClockIcon, TrophyIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

const FireMode = () => {
  const progress = ["x", "x", "x", "x", "o", "o", "o"]; // Пример прогресса (x - выполнено, o - нет)
  const days = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];

  return (
    <div className="bg-gradient-to-r from-orange-400 to-orange-500 p-4 rounded-xl shadow-lg flex flex-col space-y-3 relative">
      {/* Заголовок */}
      <div className="text-white font-bold text-lg">🔥 Огненный режим</div>

      {/* Контейнер для прогресса и дней */}
      <div className="flex space-x-2">
        {progress.map((status, index) => (
          <div key={index} className="flex flex-col items-center">
            <div
              className={`w-8 h-8 mb-1 flex items-center justify-center rounded-full ${
                status === "x" ? "bg-orange-700 text-white text-lg" : "border-2 border-white"
              }`}
            >
              {status === "x" && "✖"}
            </div>
            <span className="text-sm text-white opacity-80">{days[index]}</span>
          </div>
        ))}
      </div>



      {/* Описание */}
      <div className="bg-orange-600 p-3 rounded-lg flex items-center space-x-3">
        <div className="flex-1">
          <h3 className="text-white font-semibold">Зажги огонек эрудированности</h3>
          <p className="text-white text-sm opacity-90">
            Хочешь стать умнее, чем вчера? Смотри 5 минут саммари в день и ставь галочку на саморазвитии.
          </p>
          <div className="flex items-center mt-2 space-x-4 text-white opacity-80 text-sm">
            <div className="flex items-center">
              <ClockIcon className="w-4 h-4 mr-1" />
              5 минут до цели
            </div>
            <div className="flex items-center">
              <TrophyIcon className="w-4 h-4 mr-1" />
              Лучший результат: 0 дней
            </div>
          </div>
        </div>
        {/* Маскот */}
        <div className="w-12 h-12">
          <img
            src="https://cdn-icons-png.flaticon.com/512/4149/4149636.png"
            alt="fire mascot"
            className="w-full h-full"
          />
        </div>
      </div>

      {/* Кнопка справа */}
      <button className="absolute top-4 right-4 text-white">
        <ChevronRightIcon className="w-6 h-6" />
      </button>
    </div>
  );
};

export default FireMode;
