import React from "react";

const VideoPlayer = () => {
  return (
    <div className="w-full bg-black">
      <iframe
        className="w-full h-64 sm:h-80"
        src="https://www.youtube.com/embed/nVLIaQuVS4E"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
