import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  HomeIcon,
  MagnifyingGlassIcon,
  HeartIcon,
  UserIcon,
  LockClosedIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";

const Sidebar = () => {
  const navigate = useNavigate();

  const [stats] = useState({
    cardsOpened: 0,
    seminarsCompleted: 0,
    videosWatched: 4,
  });

  const menuItems = [
    { icon: HomeIcon, label: "Главная", path: "/training_course_test_catalog" },
    { icon: MagnifyingGlassIcon, label: "Каталог", path: "/training_course_test_catalog" },
    { icon: HeartIcon, label: "Мое", path: "/training_course_test_catalog" },
    { icon: UserIcon, label: "Профиль", path: "/training_course_test_profile" },
  ];

  return (
    <div className="w-60 bg-white rounded-2xl shadow-lg p-4 sticky top-6 h-[350px] overflow-y-auto">
      <nav>
        <ul className="space-y-1">
          {menuItems.map((item, index) => (
            <li key={index}>
              <button
                className="w-full flex items-center px-3 py-2 text-gray-700 hover:bg-gray-100 rounded-xl transition-colors"
                onClick={() => navigate(item.path)}
              >
                <item.icon className="w-5 h-5 mr-3 text-gray-400" />
                <span className="text-sm font-medium text-gray-700">{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </nav>

      <div className="mt-5 space-y-3 bg-gray-50 p-4 rounded-xl">
        <div className="flex items-center space-x-2">
          <div className="w-7 h-7 bg-gray-200 rounded-full flex items-center justify-center">
            <LockClosedIcon className="w-4 h-4 text-gray-400" />
          </div>
          <span className="text-xs text-gray-600">{stats.cardsOpened} Карточек открыто</span>
        </div>

        <div className="flex items-center space-x-2">
          <div className="w-7 h-7 bg-gray-200 rounded-full flex items-center justify-center">
            <LockClosedIcon className="w-4 h-4 text-gray-400" />
          </div>
          <span className="text-xs text-gray-600">{stats.seminarsCompleted} Саммари закончено</span>
        </div>

        <div className="flex items-center space-x-2">
          <div className="w-7 h-7 bg-blue-100 rounded-full flex items-center justify-center">
            <VideoCameraIcon className="w-4 h-4 text-blue-600" />
          </div>
          <span className="text-xs text-gray-600">{stats.videosWatched} Видео просмотрено</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
