import React from "react";

const otherLeaders = [
  { name: "Инкогнито", score: 857, place: 4, avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149651.png" },
  { name: "Видана Кожина", score: 734, place: 5, avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149645.png" },
  { name: "Яна", score: 672, place: 6, avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149646.png" },
  { name: "Сергей Владимирович", score: 575, place: 7, avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149647.png" },
  { name: "Ника", score: 545, place: 8, avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149648.png" },
  { name: "Таня", score: 542, place: 9, avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149649.png" },
  { name: "ася", score: 530, place: 10, avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149650.png" },
];

const LeaderboardList = () => {
  return (
    <div className="bg-white p-6 rounded-2xl shadow-md w-full mt-4">
      <div className="space-y-2">
        {otherLeaders.map((leader, index) => (
          <div key={index} className="flex items-center justify-between p-4 border-b last:border-none">
            {/* Левая часть */}
            <div className="flex items-center space-x-4">
              <span className="text-gray-600 font-semibold w-6">{leader.place}</span>
              <div className="w-10 h-10">
                <img src={leader.avatar} alt={leader.name} className="w-full h-full rounded-full border border-gray-300" />
              </div>
              <span className="text-lg font-medium">{leader.name}</span>
            </div>

            {/* Правая часть */}
            <span className="text-lg font-semibold text-gray-700">{leader.score}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeaderboardList;
