import React from "react";

const VideoNavigation = () => {
  return (
    <div className="flex justify-between items-center px-4 py-4 border-b">
      <button
        className="text-gray-600 hover:text-gray-800 flex items-center gap-2 transition-colors duration-200"
        onClick={() => console.log("Предыдущее видео")}
      >
        <span>&larr; Предыдущее видео</span>
      </button>

      <div className="flex flex-col items-center">
        <span className="text-sm text-gray-500 font-medium">Блок 1</span>
        <span className="text-base font-semibold text-gray-700">Видео 1</span>
      </div>

      <button
        className="text-gray-600 hover:text-gray-800 flex items-center gap-2 transition-colors duration-200"
        onClick={() => console.log("Следующее видео")}
      >
        <span>Следующее видео &rarr;</span>
      </button>
    </div>
  );
};

export default VideoNavigation;
