import React, { useState } from "react";
import Sidebar from './Training/Sidebar';

import FireMode from './Training/Profile/FireMode';
import Leaderboard from './Training/Profile/Leaderboard';
import LeaderboardList from './Training/Profile/LeaderboardList';
import UserLeaderboard from './Training/Profile/UserLeaderboard';


// Rest of the Profile component remains the same...
export default function TrainingProfile() {

  const currentUser = {
    name: "Ты",
    score: 470,
    place: 15, // Если не в топ-10
    avatar: "https://cdn-icons-png.flaticon.com/512/4149/4149655.png",
  };

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar />
        <div className="flex-1 space-y-6">
         <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-0">
            <FireMode />
         </div>

         <UserLeaderboard user={currentUser}/>
         <Leaderboard />
         <LeaderboardList />



        </div>
      </div>
    </div>
  );
}