import React, { useState } from "react";
import Sidebar from './Training/Sidebar';
import VideoNavigation from './Training/Course/VideoNavigation';
import VideoPlayer from './Training/Course/VideoPlayer';
import Info from './Training/Course/Info';
import Progress from './Training/Course/Progress';
import Blocks from './Training/Course/Blocks';


// Rest of the Training component remains the same...
export default function Training() {

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6">
      <div className="max-w-7xl mx-auto flex gap-4">
        <Sidebar />
        <div className="flex-1 space-y-5">
          <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl overflow-hidden mt-0">
            {/* Навигация по видео */}
            <VideoNavigation />
            {/* Видео */}
            <VideoPlayer />
          </div>

            {/* Заголовок и описание */}
            <Info />


        {/* Прогресс */}
        <Progress />

        {/* Блоки и видео */}
        <Blocks />
        </div>
      </div>
    </div>
  );
}