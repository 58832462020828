import React, { useState } from "react";

const Blocks = () => {
  const [activeTab, setActiveTab] = useState("Видео");

  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl p-6">
      {/* Вкладки */}
      <div className="flex bg-gray-100 p-2 rounded-lg mb-4">
        {["Видео", "Автор", "Сертификат"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`flex-grow text-center px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
              activeTab === tab
                ? "bg-white text-black shadow"
                : "bg-transparent text-gray-500"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Содержимое вкладок */}
      {activeTab === "Видео" && (
        <div>
          <h2 className="text-lg font-semibold mb-4">Блоки и видео</h2>
          <div className="flex flex-wrap gap-2 mb-4">
            {[1, 2, 3, 4, 5, 6].map((block) => (
              <button key={block} className="bg-gray-200 px-4 py-2 rounded-lg font-medium">
                Блок {block}
              </button>
            ))}
          </div>
          <h2 className="text-lg font-semibold mb-4">
            Российская столица — Москва
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {[
              { title: "1. Основание Москвы", duration: "22 минуты" },
              { title: "2. Московский Кремль", duration: "21 минута" },
              { title: "3. Архитектура", duration: "20 минута" },
              { title: "4. Большой переезд Москвы", duration: "17 минута" },
              { title: "5. Столица империи", duration: "19 минута" },
              { title: "6. Российская столица — Москва", duration: "30 минута" }
            ].map((video, index) => (
              <div key={index} className="border p-4 rounded-lg shadow hover:bg-gray-50 cursor-pointer">
                <h3 className="font-bold">{video.title}</h3>
                <p className="text-gray-500">{video.duration}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      {activeTab === "Автор" && (
        <div className="flex flex-col items-start p-6">
          <div className="flex items-center mb-4">
            <img
              src="https://kuban24.tv/wp-content/uploads/2024/04/photo_2024-04-17_13-35-52.jpg"
              alt="Аватар автора"
              className="w-20 h-20 rounded-full mr-4"
            />
            <div>
              <h3 className="text-xl font-bold">Александр Дединкин</h3>
              <p className="text-gray-500">
                Искусствовед и психолог, Институт им. И. Е. Репина, СПбГУ
              </p>
            </div>
          </div>
          <p className="text-gray-600 leading-relaxed">
            Для меня история архитектуры — это не просто работа, а моя страсть и образ
            жизни. Я посвятил ей много лет жизни, начиная с обучения в
            Санкт-Петербургской академии художеств, и продолжаю уделять ей все
            свободное время. Я с удовольствием делюсь знаниями о великих творцах,
            стилях и шедеврах мировой архитектуры, читаю лекции и провожу экскурсии,
            веду телеграм-канал, где рассказываю о малоизвестных страницах архитектуры
            Петербурга. Моя цель — показать красоту в обыденных местах и открыть города
            с новых ракурсов. Мой подход к материалу отличается тем, что у меня есть
            психологическое образование, поэтому я также говорю об искусстве с точки
            зрения его восприятия. В лекциях я использую множество примеров из жизни,
            чтобы продемонстрировать, что архитектура — это удивительное искусство,
            которое может изменить ваш взгляд на мир, путешествия и место, где вы
            живете.
          </p>
        </div>
      )}

      {activeTab === "Сертификат" && (
        <div className="flex flex-col items-center">
          <h2 className="text-lg font-semibold mb-4">Сертификат</h2>
          <img
            src="https://directacademia.ru/images/tild3932-3432-4334-b633-316431313139__4.jpg"
            alt="Сертификат"
            className="w-full max-w-md rounded-lg shadow-lg"
          />
          <p className="text-gray-600 mt-4 text-center">
            Это ваш сертификат, подтверждающий успешное прохождение курса.
          </p>
        </div>
      )}
    </div>
  );
};

export default Blocks;