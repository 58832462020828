import React from "react";

const Progress = () => {
  return (
    <div className="w-full max-w-5xl bg-white shadow-md rounded-2xl p-6">
      <h2 className="text-lg font-semibold mb-4">Прогресс</h2>
      <div className="flex flex-wrap gap-4">
        <div className="flex items-center gap-2">
          <span className="bg-blue-500 text-white text-sm px-3 py-1 rounded-full">
            0/31
          </span>
          <span>Видео просмотрено</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="bg-pink-500 text-white text-sm px-3 py-1 rounded-full">
            0/6
          </span>
          <span>Карточек открыто</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="bg-purple-500 text-white text-sm px-3 py-1 rounded-full">
            0/1
          </span>
          <span>Экзаменов сдано</span>
        </div>
      </div>
      <div className="mt-4 w-full bg-gray-200 rounded-full h-2">
        <div
          className="bg-blue-500 h-2 rounded-full"
          style={{ width: "46%" }}
        ></div>
      </div>
    </div>
  );
};

export default Progress;