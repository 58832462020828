import React from "react";
import { HeartIcon, ClockIcon, BookOpenIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const cards = [
  {
    title: "Якобинский террор: кровавый акт Французской революции",
    category: "Разобраться",
    time: "54 минуты",
    image: "https://cdn-icons-png.flaticon.com/512/4149/4149636.png",
  },
  {
    title: "«Трофейное кино»: как голливудские фильмы стали одной из причин Перестройки",
    category: "Разобраться",
    time: "50 минут",
    image: "https://cdn-icons-png.flaticon.com/512/4149/4149637.png",
  },
  {
    title: "Сюрреализм Дали: искусство искажения реальности",
    category: "Осмыслить",
    time: "59 минут",
    image: "https://cdn-icons-png.flaticon.com/512/4149/4149638.png",
  },
  {
    title: "Архитектура модерна: отказ от традиций или их переосмысление",
    category: "Осмыслить",
    time: "47 минут",
    image: "https://cdn-icons-png.flaticon.com/512/4149/4149639.png",
  },
  {
    title: "В чем особенности архитектуры Москвы",
    category: "Осмыслить",
    time: "56 минут",
    image: "https://cdn-icons-png.flaticon.com/512/4149/4149640.png",
  },
  {
    title: "Почему Эдуард Мане стал отцом искусства авангарда",
    category: "Осмыслить",
    time: "1 час",
    image: "https://cdn-icons-png.flaticon.com/512/4149/4149641.png",
  },
];

const CardGrid = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white p-6 rounded-xl shadow-md w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {cards.map((card, index) => (
        <div 
          key={index} 
          className="bg-gray-100 rounded-lg overflow-hidden shadow-md relative cursor-pointer"
          onClick={() => navigate(`/training_course_test`)} // Navigate to course details page
        >
          {/* Картинка */}
          <img src={card.image} alt={card.title} className="w-full h-40 object-cover" />

          {/* Кнопка избранного */}
          <button 
            className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-md z-10"
            onClick={(e) => {
              e.stopPropagation(); // Prevent navigating when clicking favorite button
              console.log("Добавлено в избранное:", card.title);
            }}
          >
            <HeartIcon className="w-5 h-5 text-gray-500" />
          </button>

          {/* Контент */}
          <div className="p-4">
            <h3 className="text-sm font-semibold">{card.title}</h3>
            <div className="flex items-center space-x-2 mt-2 text-sm text-gray-600">
              <BookOpenIcon className="w-4 h-4 text-blue-500" />
              <span>{card.category}</span>
              <ClockIcon className="w-4 h-4 text-gray-500" />
              <span>{card.time}</span>
            </div>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default CardGrid;
