import React, { Fragment, useRef, useState } from 'react';
import TestScale from '../../../../components/Scales/TestScale/TestScale';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '../../../../components/forms/Buttons/Button';
import { completeTest } from '../../../../services/tests/testService';
import { useNavigate } from 'react-router-dom';

const StudentTest = ({ test, questions }) => {
  const navigate = useNavigate();

  const { control, handleSubmit, watch } = useForm();
  const [nullValues, setNullValues] = useState([]);

  const questionsRef = useRef({});

  const finishTest = (data) => {
    const body = [];
    setNullValues([]);
    const nullValues = [];

    Object.keys(data).forEach((question) => {
      if (data[question] !== undefined) {
        body.push({ question, answer: { answer: data[question] } });
      } else {
        nullValues.push(question);
      }
    });

    if (nullValues.length > 0) {
      questionsRef[nullValues[0]]?.scrollIntoView();
      setNullValues(nullValues);
      return;
    }
    completeTest({ uuid: test.uuid, body })?.then((res) => {
      navigate({
        pathname: `/results/${test.uuid}`,
      });
    });
  };

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex flex-col gap-2 sticky top-0 bg-white'>
        <center className='text-2xl font-bold'>{test?.name}</center>
        <center className='text-2xl font-bold'>
          {questions
            .map((e) => watch(e.uuid) !== undefined)
            .reduce((prev, cur) => prev + cur, 0)}
          / {questions?.length}
        </center>
        <hr className='border-b border-slate-300' />
      </div>

      <form
        className='flex flex-col items-center gap-2'
        onSubmit={handleSubmit(finishTest)}
      >
        {questions?.map((question, i) => {
          const isNull = nullValues.includes(question.uuid);
          return (
            <Fragment key={question.uuid}>
              {!!i && <div className='w-1/3 border my-8' />}
              <p
                className={`${isNull && 'text-error'} font-semibold`}
                ref={(e) => (questionsRef[question.uuid] = e)}
              >
                {question.text}
                <span className='text-error text-sm'>*</span>
              </p>
              {question.content?.scale && (
                <Controller
                  control={control}
                  // rules={{ required: true }}
                  name={question.uuid}
                  render={({ field }) => (
                    <TestScale
                      current={field.value}
                      setCurrent={(e) => {
                        if (field.value === e) {
                          field.onChange();
                        } else {
                          field.onChange(e);
                        }
                      }}
                      className='text-xs'
                      startSize={10}
                      min={question.content.scale.min}
                      max={question.content.scale.max}
                      minValue={question.content.scale.minValue}
                      maxValue={question.content.scale.maxValue}
                    />
                  )}
                />
              )}
            </Fragment>
          );
        })}
        <Button text='Finish' color='success' type='submit' />
      </form>
    </div>
  );
};

export default StudentTest;
